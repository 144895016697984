import React from 'react'
import styled from 'styled-components'

import { Icon } from '@patreon/studio'

import SocialLinksData from '../../static/data'

export const Twitter = styled.a`
    align-items: center;
    background-color: #5ce54e;
    border-radius: 50%;
    display: flex;
    height: ${props => props.theme.units.getValue(4.5)};
    justify-content: center;
    min-width: ${props => props.theme.units.getValue(4.5)};
    width: ${props => props.theme.units.getValue(4.5)};
    transition: ${props => props.theme.transitions.default};
    margin-right: 10px;
`

const TwitterIcon = () => {
    const socialLinks = SocialLinksData()
    const social = socialLinks.twitter

    return (
        <Twitter aria-label={`Patreon on ${social.label}`} key={social.label}>
            {React.createElement(social.iconElementType, {
                fill: Icon.Color.Dark,
            })}
        </Twitter>
    )
}

export default TwitterIcon
