import React from 'react'
import {
    IconSocialFacebookF,
    IconSocialInstagram,
    IconSocialTwitter,
} from '@patreon/studio'

import {
    FACEBOOK_LINK,
    INSTAGRAM_LINK,
    TWITTER_LINK,
} from '../analytics/events'

const SocialLinksData = eventConstant => ({
    facebook: {
        label: 'Facebook',
        iconElementType: IconSocialFacebookF,
        href: 'https://www.facebook.com/patreon',
        event: FACEBOOK_LINK,
    },
    instagram: {
        label: 'Instagram',
        iconElementType: IconSocialInstagram,
        href: 'https://www.instagram.com/patreon',
        event: INSTAGRAM_LINK,
    },
    twitter: {
        label: 'Twitter',
        iconElementType: IconSocialTwitter,
        href: 'https://www.twitter.com/patreon',
        event: TWITTER_LINK,
    },
})

export default SocialLinksData
