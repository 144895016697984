import React from 'react'

import { Box } from '@chakra-ui/core'
import { Grid, Row } from 'react-flexbox-grid'

import AnimateIn from './animate/AnimateIn'

const WeSay = () => {
    return (
        <Box className="colorDark">
            <Box className="section space">
                <Grid>
                    <Row className="space">
                        <Box className="flexDirectionColumn displayFlex">
                            <AnimateIn>
                                <h4 className="fontColor">We Say</h4>
                            </AnimateIn>
                            <AnimateIn>
                                <Box maxHeight="80vh">
                                    <h2 className="fontLight fThat">
                                        Fuck that.
                                    </h2>
                                </Box>
                            </AnimateIn>
                        </Box>
                    </Row>
                </Grid>
            </Box>
        </Box>
    )
}

export default WeSay
