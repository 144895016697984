import React, { useCallback } from 'react'

import { Box } from '@chakra-ui/core'
import { Col, Grid, Row } from 'react-flexbox-grid'

import TwitterIcon from './addons/TwitterIcon'
import FadeIn from './animate/FadeIn'
import { useAnalytics } from '../analytics'
import { FOOTER_SHARE } from '../analytics/events'

const TwitterShare = () => {
    const logEvent = useAnalytics()
    const openShare = useCallback(() => {
        window.open(
            'https://ctt.ac/aDm44',
            'Twitter',
            'height=285,width=550,resizable=1',
        )
        logEvent(FOOTER_SHARE)
    }, [logEvent])
    return (
        <Box className="section space">
            <Grid>
                <Row>
                    <Col md={12}>
                        <FadeIn>
                            <Box className="eyebrow fontColor hashtag">
                                <TwitterIcon />
                                <a onClick={openShare}>
                                    #CreativityOverEverything
                                </a>
                            </Box>
                        </FadeIn>
                        <FadeIn>
                            <Box className="hashtagMark">
                                <h2>
                                    With everything going on in the world,{' '}
                                    <mark>we need creativity</mark> more than
                                    ever.
                                </h2>
                            </Box>
                        </FadeIn>
                    </Col>
                    <Col md={8}>
                        <Box marginTop="25px">
                            <p>
                                Let&rsquo;s celebrate those who are paving their
                                own&nbsp;path.
                            </p>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <button onClick={openShare}>share here</button>
                    </Col>
                </Row>
            </Grid>
        </Box>
    )
}

export default TwitterShare
