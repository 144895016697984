import { useEffect, useCallback, useState } from 'react'

export function useOnScrollBottom(callback, threshold = 0) {
    const [hasHitRockBottom, setHasHitRockBottom] = useState(false)
    const handleScroll = useCallback(() => {
        if (
            !hasHitRockBottom &&
            window.innerHeight + window.scrollY >=
                document.body.offsetHeight - threshold
        ) {
            setHasHitRockBottom(true)
            callback()
        }
    }, [callback, hasHitRockBottom, threshold])
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return function cleanUpScrollListener() {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll])
}
