import React, { useState, useCallback } from 'react'

import { Box } from '@chakra-ui/core'
import { Col, Grid, Row } from 'react-flexbox-grid'
import ModalVideo from 'react-modal-video'

import PlayIcon from './addons/PlayIcon'
import FadeIn from './animate/FadeIn'
import { useAnalytics } from '../analytics'
import { CLICKED_VIDEO, HERO_SHARE } from '../analytics/events'

const WhatIfWeHero = () => {
    const [isOpen, setIsOpen] = useState(false)
    const logEvent = useAnalytics()
    const openVideo = useCallback(() => {
        setIsOpen(true)
        logEvent(CLICKED_VIDEO)
    }, [logEvent])
    const openShare = useCallback(() => {
        window.open(
            'https://ctt.ac/7ebXs',
            'Twitter',
            'height = 285, width = 550, resizable = 1',
        )
        logEvent(HERO_SHARE)
    }, [logEvent])

    return (
        <Box className="colorDark">
            <Box className="section">
                <Grid>
                    <Box className="flipResponsiveOrder">
                        <Row className="fontLight videoHero">
                            <Col xs={12} mdOffset={3} md={9} padding="0">
                                <ModalVideo
                                    autoplay
                                    channel="vimeo"
                                    isOpen={isOpen}
                                    videoId="399742289"
                                    onClose={() => setIsOpen(false)}
                                />
                                <Box
                                    onClick={openVideo}
                                    cursor="pointer"
                                    className="aspectRatio"
                                >
                                    <div className="videoStill">
                                        <PlayIcon />
                                    </div>
                                </Box>
                                <Box paddingTop="25px">
                                    <button
                                        onClick={openShare}
                                        className="fontLight"
                                    >
                                        share this
                                    </button>
                                </Box>
                            </Col>
                        </Row>
                        <Box position="relative">
                            <Box className="whatBS">
                                <Row className="fontColor">
                                    <h4>How did we</h4>
                                </Row>
                                <Row className="fontLight">
                                    <FadeIn>
                                        <h1>get here?</h1>
                                    </FadeIn>
                                </Row>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Box>
        </Box>
    )
}

export default WhatIfWeHero
