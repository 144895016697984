import React, { useCallback, useMemo, useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { Box } from '@chakra-ui/core'

const DISTANCE = '10px'

const AnimateIn = ({ children, from = 'bottom', delay = '1' }) => {
    const [isShown, setIsShown] = useState(false)

    const handleVisibilityChange = useCallback(
        isVisible => {
            if (isVisible && !isShown) {
                setIsShown(true)
            }
        },
        [isShown],
    )

    const showTransform = useMemo(() => {
        switch (from) {
            case 'bottom':
                return `translateY(${DISTANCE})`
            case 'left':
                return `translateX(-${DISTANCE})`
            case 'top':
                return `translateY(-${DISTANCE})`
            case 'right':
                return `translateX(${DISTANCE})`
        }
    }, [from])

    return (
        <VisibilitySensor
            onChange={handleVisibilityChange}
            scrollDelay
            scrollThrottle
        >
            <Box
                display="inline-block"
                opacity={isShown ? 1 : 0}
                transition="all 400ms ease-in-out"
                transform={isShown ? '' : showTransform}
            >
                {children}
            </Box>
        </VisibilitySensor>
    )
}

export default AnimateIn
