import React from 'react'

import { Box } from '@chakra-ui/core'
import { Col, Grid, Row } from 'react-flexbox-grid'

import AnimateIn from './animate/AnimateIn'
import FadeIn from './animate/FadeIn'

const CreativeIdea = () => {
    return (
        <Box className="color">
            <Box className="section" paddingTop="50px" paddingBottom="50px">
                <Grid>
                    <Box className="space">
                        <Row>
                            <FadeIn>
                                <h4 className="fontDark">
                                    Here&rsquo;s an idea
                                </h4>
                            </FadeIn>
                        </Row>
                        <Row className="fontLight">
                            <FadeIn>
                                <Box display="flex">
                                    <h1>
                                        Let&rsquo;s put&nbsp;
                                        <AnimateIn from="left">
                                            creativity&nbsp;
                                        </AnimateIn>
                                        over everything.
                                    </h1>
                                </Box>
                            </FadeIn>
                        </Row>
                    </Box>
                    <Box className="space">
                        <Row>
                            <h4 className="fontDark"></h4>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <Box paddingTop="8px">
                                    <p>
                                        Because they&rsquo;re{' '}
                                        <mark>your ideas.</mark> It&rsquo;s{' '}
                                        <mark>your work</mark> that captivates
                                        audiences, inspires conversation, and
                                        builds community.
                                    </p>
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                </Grid>
            </Box>
        </Box>
    )
}

export default CreativeIdea
