import React from 'react'

import { Box, Link } from '@chakra-ui/core'
import { Col, Grid, Row } from 'react-flexbox-grid'

import PatreonLogo from './addons/PatreonLogo'
import SocialIcons from './addons/SocialIcons'
import { useAnalytics } from '../analytics'
import { FOOTER_LOGO, LEARN_MORE } from '../analytics/events'

const Footer = () => {
    const logEvent = useAnalytics()
    return (
        <Box className="color">
            <Box className="section footer">
                <Grid>
                    <Row>
                        <Col xs={12} md={4}>
                            <Box marginBottom="25px">
                                <PatreonLogo
                                    colorFill
                                    onClick={() => logEvent(FOOTER_LOGO)}
                                    width={{ xs: 5, md: 7, lg: 8 }}
                                />
                            </Box>
                        </Col>
                        <Col xs={0} md={8}>
                            <Box className="desktop">
                                <h4 className="fontDark lite">
                                    Hey, we&rsquo;re Patreon
                                </h4>
                                <Box className="space">
                                    <p>
                                        We help creators like you change the way
                                        their art is valued by building a direct
                                        relationship with their most engaged
                                        audience. Once subscribed, their fans
                                        receive access to special benefits,
                                        community, and the chance to become
                                        active participants in the work they
                                        love.{' '}
                                        <Link
                                            className="fontLight"
                                            href="https://www.patreon.com/"
                                            onClick={() => logEvent(LEARN_MORE)}
                                        >
                                            Learn&nbsp;more.
                                        </Link>
                                    </p>
                                </Box>
                                <SocialIcons />
                                <Box marginTop="25px">
                                    <p className="fontLight copyright">
                                        &#169; 2020 Patreon
                                    </p>
                                </Box>
                            </Box>
                        </Col>
                    </Row>

                    <Box className="mobile">
                        <h4>Hey, we&rsquo;re Patreon</h4>
                        <Box className="space">
                            <p>
                                We help creators like you change the way your
                                art is valued by building a direct relationship
                                with your most engaged audience. Once
                                subscribed, your fans receive access to special
                                benefits, community, and the chance to become
                                active participants in the work they love.{' '}
                                <Link
                                    className="fontLight"
                                    href="https://www.patreon.com/"
                                    onClick={() => logEvent(LEARN_MORE)}
                                >
                                    Learn&nbsp;more.
                                </Link>
                            </p>
                        </Box>
                        <SocialIcons />
                        <p className="fontLight copyright">
                            &#169; 2020 Patreon
                        </p>
                    </Box>
                </Grid>
            </Box>
        </Box>
    )
}

export default Footer
