import { useCallback, useEffect, useState } from 'react'

export const useIsCentered = ref => {
    const [isCentered, setIsCentered] = useState(false)

    const handleEvent = useCallback(() => {
        const { top, bottom } = ref.current.getBoundingClientRect()
        const middle = window.innerHeight / 2
        const elementIsCentered = top < middle && bottom > middle

        if (elementIsCentered && !isCentered) {
            setIsCentered(true)
        } else if (!elementIsCentered && isCentered) {
            setIsCentered(false)
        }
    }, [ref, isCentered, setIsCentered])

    useEffect(() => {
        window.addEventListener('scroll', handleEvent)
        window.addEventListener('resize', handleEvent)

        return () => {
            window.removeEventListener('scroll', handleEvent)
            window.removeEventListener('resize', handleEvent)
        }
    }, [handleEvent])

    return isCentered
}
