import React, { useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'

import { ThemeProvider } from 'styled-components'
import { themeRefresh } from '@patreon/studio'
import { ParallaxProvider } from 'react-scroll-parallax'

import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

import Header from './components/Header'
import WhatIfWeHero from './components/WhatIfWeHero'
import WatchTheFilm from './components/WatchTheFilm'
import SystemIsBroken from './components/SystemIsBroken'
import ItPuts from './components/ItPuts'
import PeopleSay from './components/PeopleSay'
import WeSay from './components/WeSay'
import CreativeIdea from './components/CreativeIdea'
import ParralaxGallery from './components/ParralaxGallery'
import TwitterShare from './components/TwitterShare'
import Footer from './components/Footer'
import { useAnalytics } from './analytics'
import { LANDED, SCROLL_BOTTOM } from './analytics/events'
import { useOnScrollBottom } from './hooks/useOnScrollBottom'

// Facebook Pixels
const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
}
ReactPixel.init('267568703576098', options)
ReactPixel.pageView()

// Google Analytics
ReactGA.initialize('UA-40726300-1')
ReactGA.pageview('/')

const CreativityOverEverything = () => {
    const logEvent = useAnalytics()
    useEffect(() => {
        logEvent(LANDED)
    }, [logEvent])
    const logScrolledToBottom = useCallback(() => {
        logEvent(SCROLL_BOTTOM)
    }, [logEvent])
    useOnScrollBottom(logScrolledToBottom, 640)

    return (
        <ThemeProvider theme={themeRefresh}>
            <ParallaxProvider>
                <Header />
                <WhatIfWeHero />
                <WatchTheFilm />
                <SystemIsBroken />
                <ItPuts />
                <PeopleSay />
                <WeSay />
                <CreativeIdea />
                <ParralaxGallery />
                <TwitterShare />
                <Footer />
            </ParallaxProvider>
        </ThemeProvider>
    )
}

var mountNode = document.getElementById('app')
ReactDOM.render(<CreativityOverEverything />, mountNode)
