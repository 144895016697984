import { useCallback } from 'react'
import queryString from 'query-string'

const TRACKING_ENDPOINT = 'https://www.patreon.com/api/tracking'
const UTM_SESSION_KEY = 'creativity-over-everything-utm'
const UTM_PARAMS = [
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
]

function getUTMParams() {
    const sessionParams = JSON.parse(
        sessionStorage.getItem(UTM_SESSION_KEY) || '{}',
    )
    if (Object.keys(sessionParams).length) {
        return sessionParams
    }
    const queries = queryString.parse(window.location.search)
    const utmParams = {}
    for (const [query, value] of Object.entries(queries)) {
        if (UTM_PARAMS.includes(query)) {
            utmParams[query] = value
        }
    }
    sessionStorage.setItem(UTM_SESSION_KEY, JSON.stringify(utmParams))
    return utmParams
}

function getAnalyticsMetaData() {
    const referrer = window.document.referrer
    const referrerUrl =
        referrer && !referrer.startsWith('http')
            ? `http://${referrer}`
            : referrer
    const referredFromSelf = referrerUrl.includes(window.location.hostname)
    const utmParams = getUTMParams()
    return {
        referrer_url: referredFromSelf ? undefined : referrerUrl,
        ...utmParams,
    }
}

/**
 * React hook that provides access to the analytics event logging function.
 * @returns logEvent(eventName, eventProperties)
 */
export function useAnalytics() {
    const logEvent = useCallback((eventName, eventProperties) => {
        const metadata = getAnalyticsMetaData()
        const { referrer_url, ...utmParams } = metadata

        // tracking endpoint expects an array of events
        const events = [
            {
                event_type: eventName,
                event_properties: { ...eventProperties, ...metadata },
                user_properties: { ...utmParams },
                client_event_time: new Date().getTime(),
                client_upload_time: new Date().getTime(),
            },
        ]
        const request = new XMLHttpRequest()
        request.open('POST', TRACKING_ENDPOINT)
        /* Required for sending cookies */
        request.withCredentials = true
        request.setRequestHeader(
            'Content-Type',
            'application/json;charset=UTF-8',
        )
        if (process.env.NODE_ENV === 'development') {
            console.log('Event: ', events)
        } else {
            request.send(JSON.stringify(events))
        }
    }, [])
    return logEvent
}
