import React from 'react'
import { SvgImage } from '@patreon/studio'

export default function PlayIcon() {
    return (
        <SvgImage strokeWidth={SvgImage.StrokeWidth.Thick}>
            <svg
                className="playIcon"
                x="0px"
                y="0px"
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="38.144" cy="38.328" r="37.608" fill="#5CE54E" />
                <path
                    d="M53.02 38.357L29.256 52V24.713L53.02 38.357z"
                    fill="#fff"
                />
            </svg>
        </SvgImage>
    )
}
