import React from 'react'
import styled from 'styled-components'

import { Stack } from '@patreon/studio'

import SocialLinksData from '../../static/data'
import { useAnalytics } from '../../analytics'

export const SocialButton = styled.a`
    align-items: center;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 50%;
    display: flex;
    height: ${props => props.theme.units.getValue(4.5)};
    justify-content: center;
    min-width: ${props => props.theme.units.getValue(4.5)};
    width: ${props => props.theme.units.getValue(4.5)};
    transition: ${props => props.theme.transitions.default};

    &:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }
`

const SocialIcons = () => {
    const socialLinks = SocialLinksData()
    const logEvent = useAnalytics()
    return (
        <Stack direction={Stack.Direction.Horizontal} spacing={1.5}>
            {Object.keys(socialLinks).map(key => {
                const social = socialLinks[key]
                return (
                    <SocialButton
                        aria-label={`Patreon on ${social.label}`}
                        href={social.href}
                        key={social.label}
                        onClick={() => logEvent(social.event)}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {React.createElement(social.iconElementType, {
                            fill: '#5CE54E',
                        })}
                    </SocialButton>
                )
            })}
        </Stack>
    )
}

export default SocialIcons
