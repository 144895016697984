import React from 'react'

import { Box } from '@chakra-ui/core'
import { Grid, Row } from 'react-flexbox-grid'

import AnimateIn from './animate/AnimateIn'

const PeopleSay = () => {
    return (
        <Box className="colorDark">
            <Box className="section space">
                <Grid>
                    <Row className="space">
                        <Box className="flexDirectionColumn displayFlex">
                            <AnimateIn>
                                <h4 className="fontColor">People Say</h4>
                            </AnimateIn>
                            <AnimateIn>
                                <h2 className="fontLight">
                                    That&rsquo;s just the{' '}
                                    <mark>way it is.</mark>
                                </h2>
                            </AnimateIn>
                        </Box>
                    </Row>
                </Grid>
            </Box>
        </Box>
    )
}

export default PeopleSay
