import React, { useState } from 'react'

import { Box } from '@chakra-ui/core'
import { Icon, IconArrowUp } from '@patreon/studio'
import { Grid } from 'react-flexbox-grid'

import ModalVideo from 'react-modal-video'

const ScrollButton = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Box className="colorDark">
            <Box className="section">
                <Grid>
                    <ModalVideo
                        autoplay
                        channel="vimeo"
                        isOpen={isOpen}
                        videoId="399742289"
                        onClose={() => setIsOpen(false)}
                    />
                    <Box
                        alignItems="center"
                        className="section fontLight colorDark"
                        cursor="pointer"
                        display="flex"
                        flexDirection="column"
                        onClick={() => setIsOpen(true)}
                    >
                        <IconArrowUp
                            size={{ xs: Icon.Size.Sm, lg: Icon.Size.Lg }}
                            stroke={[Icon.Color.BrandLight]}
                            title="Back to top"
                        />
                        <p className="light">Watch the film</p>
                    </Box>
                </Grid>
            </Box>
        </Box>
    )
}

export default ScrollButton
