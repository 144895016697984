const DOMAIN = 'Creativity Page'

const makeEvent = name => `${DOMAIN} : ${name}`

export const LANDED = makeEvent('Landed')
export const HEADER_LOGO = makeEvent('Clicked Header Logo')
export const CLICKED_VIDEO = makeEvent('Clicked Brand Video')
export const HERO_SHARE = makeEvent('Clicked Hero Share')
export const FOOTER_SHARE = makeEvent('Clicked Footer Share')
export const FOOTER_LOGO = makeEvent('Clicked Footer Logo')
export const LEARN_MORE = makeEvent('Clicked Learn More')
export const FACEBOOK_LINK = makeEvent('Clicked Facebook Link')
export const INSTAGRAM_LINK = makeEvent('Clicked Instagram Link')
export const TWITTER_LINK = makeEvent('Clicked Twitter Link')
export const SCROLL_BOTTOM = makeEvent('Scrolled to Bottom')
