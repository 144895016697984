import React from 'react'

import { Link } from '@chakra-ui/core'
import { SvgImage } from '@patreon/studio'

export default function PatreonLogo({ colorFill, onClick, width }) {
    return (
        <Link href="https://www.patreon.com/" onClick={onClick}>
            <SvgImage
                width={width}
                fill={colorFill ? '#141518' : SvgImage.Fill.Light}
                strokeWidth={SvgImage.StrokeWidth.Thick}
            >
                <svg
                    viewBox="0 0 64 62"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M41.028 0C28.322 0 17.984 10.336 17.984 23.042c0 12.667 10.337 22.972 23.044 22.972C53.695 46.014 64 35.71 64 23.042 64 10.336 53.695 0 41.028 0zM0 61.376h11.251V0H0v61.376z"
                        data-fill="1"
                    />
                </svg>
            </SvgImage>
        </Link>
    )
}
