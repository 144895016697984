import React from 'react'

import { Box } from '@chakra-ui/core'
import { Col, Grid, Row } from 'react-flexbox-grid'

import AnimateIn from './animate/AnimateIn'
import FadeIn from './animate/FadeIn'

const SystemIsBroken = () => {
    return (
        <Box className="colorDark">
            <Box className="section">
                <Grid>
                    <Box className="space">
                        <Row className="fontLight">
                            <FadeIn>
                                <h2>
                                    The system for
                                    <br />
                                    creative people
                                </h2>
                            </FadeIn>
                        </Row>
                        <Row className="fontColor">
                            <Col xsOffset={4} xs={8} mdOffset={5} md={7}>
                                <AnimateIn delay={2}>
                                    <h2>
                                        <mark>is broken.</mark>
                                    </h2>
                                </AnimateIn>
                            </Col>
                        </Row>
                    </Box>
                </Grid>
            </Box>
        </Box>
    )
}

export default SystemIsBroken
