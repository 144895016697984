import React from 'react'
import { Box } from '@chakra-ui/core'

import PatreonLogo from './addons/PatreonLogo'

import { useAnalytics } from '../analytics'
import { HEADER_LOGO } from '../analytics/events'

const Header = () => {
    const logEvent = useAnalytics()
    return (
        <Box className="colorDark header">
            <Box className="section ">
                <PatreonLogo
                    onClick={() => logEvent(HEADER_LOGO)}
                    width={{ xs: 3, md: 4 }}
                />
            </Box>
        </Box>
    )
}

export default Header
