import React, { useRef } from 'react'

import { Box } from '@chakra-ui/core'
import { Col, Grid, Row } from 'react-flexbox-grid'

import { useIsCentered } from '../hooks/useIsCentered'

const Line = ({ children }) => {
    const ref = useRef()
    const isCentered = useIsCentered(ref)

    return (
        <Box className="line space light" ref={ref}>
            <h5 className={isCentered ? 'isPutsHighlight' : 'itPutsSubdued'}>
                {children}
            </h5>
        </Box>
    )
}

const ItPuts = () => {
    return (
        <Box className="colorDark">
            <Box className="section">
                <Grid>
                    <Box className="space">
                        <Row>
                            <Col xs={4} md={3}>
                                <Box className="itPutsSticky fontColor">
                                    <h3 className="itPuts">It puts</h3>
                                </Box>
                            </Col>
                            <Col xs={8} md={9}>
                                <Line>algorithms over ideas.</Line>
                                <Line>quantity over quality.</Line>
                                <Line>
                                    what&rsquo;s easy to sell over what&rsquo;s
                                    good.
                                </Line>
                                <Line>
                                    money, brands, and just about everything
                                    else over the people who actually make the
                                    things.
                                </Line>
                            </Col>
                        </Row>
                    </Box>
                </Grid>
            </Box>
        </Box>
    )
}

export default ItPuts
