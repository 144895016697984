import React, { useLayoutEffect } from 'react'

import { Box } from '@chakra-ui/core'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { Parallax, useController } from 'react-scroll-parallax'

import WGG from '../static/WGG.png'
import RZ from '../static/RZ.png'
import WFP from '../static/WFP.png'
import RR from '../static/RR.png'
import GD from '../static/GD.png'
import Fran from '../static/Fran.png'

const ParralaxGallery = () => {
    const { parallaxController } = useController()
    const WGG2x = `${WGG} 2x`
    const RZ2x = `${RZ} 2x`
    const WFP2x = `${WFP} 2x`
    const RR2x = `${RR} 2x`
    const GD2x = `${GD} 2x`
    const Fran2x = `${Fran} 2x`

    useLayoutEffect(() => {
        const handler = () => parallaxController.update()
        window.addEventListener('load', handler)
        return () => window.removeEventListener('load', handler)
    }, [parallaxController])

    return (
        <Box className="section parallax">
            <Box className="desktop">
                <Grid>
                    <Row>
                        <Col xs={4}>
                            <Parallax y={[-10, 10]}>
                                <img
                                    id="image"
                                    alt="WGG"
                                    src={WGG}
                                    srcSet={WGG2x}
                                />
                                <img
                                    id="image"
                                    alt="RZ"
                                    src={RZ}
                                    srcSet={RZ2x}
                                />
                                <img
                                    id="image"
                                    alt="Wong Fu Productions"
                                    src={WFP}
                                    srcSet={WFP2x}
                                />
                            </Parallax>
                        </Col>
                        <Col xs={4}>
                            <Parallax y={[-10, 30]}>
                                <img
                                    id="image"
                                    alt="Righteous Ratchet"
                                    src={RR}
                                    srcSet={RR2x}
                                />
                                <img
                                    id="image"
                                    alt="GD"
                                    src={GD}
                                    srcSet={GD2x}
                                />
                                <img
                                    id="image"
                                    alt="Fran"
                                    src={Fran}
                                    srcSet={Fran2x}
                                />
                            </Parallax>
                        </Col>
                        <Col xs={4} className="space">
                            <Box marginTop="50px">
                                <Box className="space">
                                    <h3>
                                        It&rsquo;s time to <mark>ditch</mark>{' '}
                                        the old ways.
                                    </h3>
                                </Box>
                                <Box className="space">
                                    <h3>
                                        Let your fans give you the{' '}
                                        <mark>freedom & stability</mark> you
                                        need to do your best creative work.{' '}
                                    </h3>
                                </Box>
                                <Box className="space">
                                    <h3>
                                        It&rsquo;s not rocket science,&nbsp;
                                        <mark>it&rsquo;s just way better.</mark>
                                    </h3>
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Grid>
            </Box>

            <Box className="mobile">
                <Grid>
                    <Row>
                        <Col>
                            <h2>It&rsquo;s time to</h2>
                            <h2>
                                <mark>ditch</mark> the old ways.
                            </h2>
                        </Col>
                        <Col>
                            <Parallax x={[100, -150]}>
                                <Box className="displayFlex">
                                    <img
                                        id="image"
                                        alt="WGG"
                                        src={WGG}
                                        srcSet={WGG2x}
                                    />
                                    <img
                                        id="image"
                                        alt="RZ"
                                        src={RZ}
                                        srcSet={RZ2x}
                                    />
                                    <img
                                        id="image"
                                        alt="Wong Fu Productions"
                                        src={WFP}
                                        srcSet={WFP2x}
                                    />
                                </Box>
                            </Parallax>
                        </Col>
                        <Col>
                            <h2>
                                Let your fans give you the{' '}
                                <mark>freedom & stability</mark> you need to do
                                your best creative work.{' '}
                            </h2>
                        </Col>
                        <Col>
                            <Parallax x={[-100, 100]}>
                                <Box className="displayFlex">
                                    <img
                                        id="image"
                                        alt="Righteous Ratchet"
                                        src={RR}
                                        srcSet={RR2x}
                                    />
                                    <img
                                        id="image"
                                        alt="GD"
                                        src={GD}
                                        srcSet={GD2x}
                                    />
                                    <img
                                        id="image"
                                        alt="Fran"
                                        src={Fran}
                                        srcSet={Fran2x}
                                    />
                                </Box>
                            </Parallax>
                        </Col>
                        <Col>
                            <h2>
                                It&rsquo;s not rocket science,&nbsp;
                                <mark>it&rsquo;s just way better.</mark>
                            </h2>
                        </Col>
                    </Row>
                </Grid>
            </Box>
        </Box>
    )
}

export default ParralaxGallery
