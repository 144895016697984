import React, { useCallback, useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { Box } from '@chakra-ui/core'

const FadeIn = ({ children, delay = '1' }) => {
    const [isShown, setIsShown] = useState(false)

    const handleVisibilityChange = useCallback(
        isVisible => {
            if (isVisible && !isShown) {
                setIsShown(true)
            }
        },
        [isShown],
    )

    return (
        <VisibilitySensor onChange={handleVisibilityChange}>
            <Box opacity={isShown ? 1 : 0} transition="all 300ms ease-in-out">
                {children}
            </Box>
        </VisibilitySensor>
    )
}

export default FadeIn
